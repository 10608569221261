import { ExtendedRelationalPhoneNumber } from 'apis/porting/porting.types';
import { useLocationTcrBrandQuery } from 'apis/tendlc/queries';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLocation } from 'redux/actions/location/current-location';
import { PortOutUtils } from '../utils/port-out.utils';

const TELNYX_PIN = '0096';
const DIVIDER = '-----------------------------------';

export const useDownloadCsrTemplate = () => {
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const currentLocation = useSelector(selectCurrentLocation);

  const { data: tcrData } = useLocationTcrBrandQuery({
    locationId: currentLocation.LocationID,
  });

  const downloadCsrTemplate = useCallback(
    (numbersList: ExtendedRelationalPhoneNumber[] = []) => {
      setIsDownloadInProgress(true);
      const { bandwidthNumbers, telnyxNumbers, otherNumbers } =
        PortOutUtils.categoriseNumbersIntoProviders(numbersList);

      const { accountNumber, address, companyName } =
        PortOutUtils.getBusinessDetailsFromTcrData({
          tcrData,
          slug: currentLocation.Slug,
        });

      const addressString = [
        address?.street,
        address?.city,
        address?.postalCode,
        address?.country,
      ]
        .filter((part) => !!part)
        ?.join(', ');

      const fileContent = [
        `Account Number: ${accountNumber}`,
        `Company Name: ${companyName}`,
        `Address: ${addressString}`,
        '',
        'Telephone and Fax numbers:',
        DIVIDER,
        bandwidthNumbers?.length
          ? [
              ...bandwidthNumbers,
              '',
              `PIN: ${currentLocation.LocationID.slice(-4)}`,
              DIVIDER,
            ]
          : [],
        telnyxNumbers?.length
          ? [...telnyxNumbers, '', `PIN: ${TELNYX_PIN}`, DIVIDER]
          : [],
        otherNumbers?.length ? [...otherNumbers] : [],
      ]
        .flat()
        .join('\n');

      const fileName = 'csr-template.txt';
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:text/plain;charset=utf-8,${encodeURIComponent(fileContent)}`
      );
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setIsDownloadInProgress(false);
    },
    [tcrData, currentLocation]
  );

  return {
    downloadCsrTemplate,
    isDownloadInProgress,
  };
};
