import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';

export const FeatureEnumToNameMap: Partial<Record<Feature, string>> = {
  [Feature.CUSTOM_CONTACTS]: 'Custom Contacts',
  [Feature.INTEGRATED]: 'Integrated',
  [Feature.LIST_HOME_ICON]: 'List',
  [Feature.BIRTHDAY_LISTS]: 'Birthday Lists',
  [Feature.COLLECTIONS_LIST]: 'Collections List',
  [Feature.FOLLOW_UPS_LIST]: 'Follow-ups List',
  [Feature.OVERDUE_PATIENTS_LIST]: 'Overdue Patients List',
  [Feature.RECOVERY_KIT]: 'Recovery Kit',
  [Feature.RESPONSE_KIT]: 'Response Kit',
  [Feature.WEAVE_VERIFY]: 'Weave Verify',
  [Feature.ANALYTICS_HOME_ICON]: 'Analytics',
  [Feature.ENTERPRISE_ANALYTICS]: 'Enterprise Analytics',
  [Feature.PRACTICE_ANALYTICS]: 'Practice Analytics',
  [Feature.CALL_REPORTS]: 'Call Reports',
  [Feature.RECALL_REPORTS]: 'Recall Reports',
  [Feature.PHONE_HOME_ICON]: 'Phone',
  [Feature.CLICK_TO_CALL]: 'Click-to-Call',
  [Feature.ONLINE_SCHEDULING]: 'Online Scheduling',
  [Feature.QUICKFILL]: 'Quickfill',
  [Feature.SCHEDULE_EVENTS]: 'Schedule Events',
  [Feature.APPOINTMENT_WRITEBACKS]: 'Appt Writebacks',
  [Feature.APPT_CONFIRMATIONS_WRITEBACKS]: 'Appointment Confirmations - writebacks',
  [Feature.CHAT]: 'Chat',
  [Feature.FAX]: 'Fax',
  [Feature.EMAIL]: 'Email',
  [Feature.TEXT_CONNECT]: 'Text Connect',
  [Feature.MASS_COMMUNICATIONS]: 'Mass Communications',
  [Feature.VOICEMAIL_TRANSCRIPTION]: 'Voicemail Transciption',
  [Feature.BULK_MESSAGING]: 'Bulk Messaging',
  [Feature.AUTO_APPOINTMENT_REMINDER]: 'Auto Appointment Reminder',
  [Feature.AUTO_BIRTHDAY_MESSAGE]: 'Auto Birthday Message',
  [Feature.AUTO_ORDER_READY_MESSAGE]: 'Auto Order Ready Message',
  [Feature.AUTO_MISSED_CALL_MESSAGE]: 'Auto Missed Call Message',
  [Feature.AUTO_RECALL_REMINDER]: 'Auto Recall Reminder',
  [Feature.AUTO_MISSED_TEXT_MESSAGE]: 'Auto Missed Text Message',
  [Feature.AUTO_REACTIVATION_REMINDER]: 'Auto Reactivation Reminder',
  [Feature.AUTO_REVIEW_REQUEST]: 'Auto Review Request',
  [Feature.REVIEWS_HOME_ICON]: 'Reviews',
  [Feature.FORMS]: 'Forms',
  [Feature.PAYMENT_TERMINALS]: 'Payment Terminals',
  [Feature.WEAVE_PAY]: 'Payments',
  [Feature.CALL_INTELLIGENCE]: 'Call Intelligence',
  [Feature.EMAIL_MARKETING]: 'Email Marketing',
};
