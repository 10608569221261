import {
  Country,
  EntityType,
  LocationTCRBrand,
} from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { FaxServiceProvider } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/fax_provider.pb';
import { VoiceServiceProvider } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/voice_provider.pb';
import { ExtendedRelationalPhoneNumber, NumberType } from 'apis/porting/porting.types';

const categoriseNumbersIntoProviders = (
  numbersList: ExtendedRelationalPhoneNumber[]
): { bandwidthNumbers: string[]; telnyxNumbers: string[]; otherNumbers: string[] } => {
  return numbersList.reduce<{
    bandwidthNumbers: string[];
    telnyxNumbers: string[];
    otherNumbers: string[];
  }>(
    (acc, current) => {
      const voiceProvider = current?.number?.voiceProvider?.provider;
      const faxProvider = current?.number?.faxProvider?.faxProvider;

      const isBandwidth =
        current.type === NumberType.FAX
          ? faxProvider === FaxServiceProvider.FAX_SERVICE_PROVIDER_BANDWIDTH_TO_TELNYX
          : voiceProvider === VoiceServiceProvider.VOICE_PROVIDER_BANDWIDTH;

      const isTelnyx =
        current.type === NumberType.FAX
          ? faxProvider === FaxServiceProvider.FAX_SERVICE_PROVIDER_TELNYX
          : voiceProvider === VoiceServiceProvider.VOICE_PROVIDER_TELNYX;

      if (isBandwidth) {
        acc.bandwidthNumbers.push(current.phoneNumber);
      } else if (isTelnyx) {
        acc.telnyxNumbers.push(current.phoneNumber);
      } else {
        acc.otherNumbers.push(current.phoneNumber);
      }
      return acc;
    },
    { bandwidthNumbers: [], telnyxNumbers: [], otherNumbers: [] }
  );
};

const getBusinessDetailsFromTcrData = ({
  slug,
  tcrData,
}: {
  slug: string;
  tcrData?: LocationTCRBrand;
}) => {
  const tcrBrand = tcrData?.tcrBrand;
  return {
    accountNumber: slug.replace(/^s00*/, ''), // Remove starting s00 or s000 from slug
    companyName:
      tcrBrand?.entityType === EntityType.ENTITY_TYPE_SOLE_PROPRIETOR
        ? tcrBrand?.displayName
        : tcrBrand?.companyName,
    address: {
      street: tcrBrand?.street,
      city: tcrBrand?.city,
      postalCode: tcrBrand?.postalCode,
      country: tcrBrand?.country === Country.COUNTRY_US ? 'USA' : 'Canada',
    },
  };
};

export const PortOutUtils = {
  categoriseNumbersIntoProviders,
  getBusinessDetailsFromTcrData,
};
